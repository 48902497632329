import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2879b82f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left_nav" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "one_title title" }
const _hoisted_4 = {
  key: 0,
  class: "tip"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "one_title title" }

import { onUnmounted, ref, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  setup(__props) {

const store = useStore();
const { enquiryNum } = toRefs(store.state.$user);
const router = useRouter();
const route = useRoute();
const helpRoutes = [
  { path: "/user/home", title: "个人中心", icon: "&#xe773;",class:'more_small_icon' },
  {
    title: "企业认证",
    icon: "&#xe760;",
    children: [
      { path: "/user/basis", title: "身份认证" },
      { path: "/user/person/open", title: "提现认证" },
    ],
  },
  {
    title: "我的交易",
    icon: "&#xe7a0;",
    class: "more_small_icon",
    children: [
      { path: "/user/balance", title: "我的账户" },
      { path: "/user/lock/order", title: "我的订单" },
      { path: "/user/lock/release", title: "我的发布" },
    ],
  },
  {
    title: "我的流水",
    icon: "&#xe776;",
    class: "more_small_icon",
    children:[
      {path: "/user/deal/flow",title: "资金流水"},
      { path: "/user/lock/bill", title: "定金流水" },
      { path: "/user/lock/reward", title: "新客奖励" },
    ]
  },
  { path: "/user/bank",title: "银行卡",icon: "&#xe691;",},
  { path: "/user/task",title: "任务管理",icon: "&#xe7f3;",class:'more_small_icon'},
  { path: "/user/enquiry/msg", title: "询价消息", icon: "&#xe68d;" },
  {
    title: "账号管理",
    icon: "&#xe818;",
    children: [{ path: "/user/data", title: "个人资料" }],
  },
  // {
  //   title: "客户管理",
  //   icon: "&#xe65c;",
  //   path: "/user/customer",
  // },
  {
    title: "功能介绍",
    icon: "&#xe758;",
    class: "function_icon",
    children: [
      { path: "/user/buy", title: "我要买货" },
      { path: "/user/sell", title: "我要卖货" },
      { path: "/user/payment", title: "钢付宝" },
      { path: "/user/sale", title: "销售端" },
    ],
  },
];

let msg = ref()
let _timer  = setInterval(()=>{
  msg.value = msg.value ? '' : '新消息'
},2000)
onUnmounted(()=>{
  clearInterval(_timer)
  _timer = null
})

return (_ctx, _cache) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")
  const _component_el_menu = _resolveComponent("el-menu")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { class: "tac" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu, {
          "active-text-color": "#496ed3",
          "unique-opened": "",
          router: "",
          "default-active": _unref(route).path
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(helpRoutes, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (!item.children)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      key: 0,
                      index: item.path
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(['iconfont', 'icon', item.class]),
                          innerHTML: item.icon
                        }, null, 10, _hoisted_2),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1),
                        (item.path === '/user/enquiry/msg'&&_unref(enquiryNum)>0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(msg) || _unref(enquiryNum)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["index"]))
                  : (_openBlock(), _createBlock(_component_el_sub_menu, {
                      key: 1,
                      index: index
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(['iconfont', 'icon', item.class]),
                          innerHTML: item.icon
                        }, null, 10, _hoisted_5),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1)
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (childItem, childIndex) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: childItem.title
                          }, [
                            (!childItem.children)
                              ? (_openBlock(), _createBlock(_component_el_menu_item_group, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_menu_item, {
                                      index: childItem.path
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(childItem.title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["index"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["index"]))
              ], 64))
            }), 64))
          ]),
          _: 1
        }, 8, ["default-active"])
      ]),
      _: 1
    })
  ]))
}
}

}